import { logo } from '../../utils/images';
import { Link } from 'react-router-dom';

interface AngelLogoProps {
  type?: string;
}

const AngelLogo: React.FC<AngelLogoProps> = ({ type }) => {
  return (
    <Link to={type === 'home' ? '/home' : '/'}>
      <img src={logo} alt="Logo" className="tw-max-w-full md:tw-max-w-48" />
    </Link>
  );
};

export default AngelLogo;
