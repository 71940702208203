import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FeedsCard from '../modules/feeds/components/FeedsCard';
import FeedsProfileCard from '../modules/feeds/components/FeedsProfileCard';
import { dummyProfile } from '../utils/images';
import OldApi from '../api-old/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from '../context/RoleProvider';
import CreatePostModal from '../modules/post/components/CreatePostModal';
import { useToggle } from '../shared/hooks/useToggle';
import HelperBanner from './HelperBanner';
import useUserStore from '../shared/stores/user.store';
import useRoleStore from '../shared/stores/role.store';

const FeedsContent = () => {
  const navigate = useNavigate();
  const [investors, setInvestors] = useState();
  const [startups, setStartups] = useState();
  const [followedProfiles, setFollowedProfiles] = useState();
  const { setActiveMenuItem } = useContext(RoleContext);
  const { currentRole } = useRoleStore();
  const { profilePhoto, companyProfilePhoto } = useUserStore();
  const [newsFeed, setNewsFeed] = useState();
  const [showHelper, setShowHelper] = useState(false);
  const token = localStorage.getItem('token');
  const { open: showPostModal, onClose: onClosePostModal, onOpen: onOpenPostModal } = useToggle();

  const getNewsFeed = useCallback(async () => {
    try {
      const response = await OldApi.getNewsFeed(token, currentRole === 'investor' ? 'investor' : 'startup');
      if (response.success) {
        const reversedData = response.data.reverse();
        setNewsFeed(reversedData);
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }, [token, currentRole]);

  const getRecentProfiles = useCallback(
    async filter => {
      try {
        const response = await OldApi.getRecentProfiles(
          token,
          currentRole === 'investor' ? 'investor' : 'startup',
          '',
          filter,
        );
        if (response.success) {
          setInvestors(response?.data?.investors);
          setStartups(response?.data?.startups);
          setFollowedProfiles(response?.data?.followedProfiles);
          if (response?.data?.followedProfiles?.length >= 5) {
            setShowHelper(false);
          }
          else {
            setShowHelper(true);
          }
        }
      }
      catch (error) {
        toast.error(error.message);
      }
    },
    [token, currentRole],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getNewsFeed();
    getRecentProfiles('');
  }, [getNewsFeed, getRecentProfiles]);

  const handleClose = () => {
    setShowHelper(false);
  };

  const handleViewAllClick = value => {
    localStorage.setItem('activeMenuItem', value);

    setActiveMenuItem(value);
    navigate(`/${value}`);
  };

  return (
    <Container fluid className="main-content dashboard-main">
      <Row className="pt-4 pb-4 dash-content">
        <Col xl={8} lg={24} md={24} sm={24} xs={24}>
          <h1 className="trending-heading trend-heading mb-0">
            Your News feed
            <span className="secondary-text slash-trending">_</span>
          </h1>
          {showHelper && <HelperBanner onClose={handleClose} />}

          {/* TODO: We haven't implemented news feed for syndicate so currently hide this section, will open it later */}
          {currentRole !== 'syndicate' && (
            <div className="feeds-container mt-3">
              <div className=" d-flex gap-2 flex-row align-items-center w-100 justify-content-between">
                <div className="profilep-img profile-update-img">
                  <img
                    src={
                      currentRole === 'investor' && profilePhoto && profilePhoto !== 'null'
                        ? profilePhoto
                        : currentRole === 'investor' &&
                            (!profilePhoto || profilePhoto === 'null' || profilePhoto === 'undefined')
                          ? dummyProfile
                          : currentRole === 'startup' &&
                              (!companyProfilePhoto ||
                                companyProfilePhoto === 'null' ||
                                companyProfilePhoto === 'undefined')
                            ? dummyProfile
                            : currentRole === 'startup' && companyProfilePhoto
                              ? companyProfilePhoto
                              : dummyProfile
                    }
                    alt=""
                  />
                </div>
                <button className="btn-post" onClick={onOpenPostModal}>
                  Start a post
                </button>
              </div>
            </div>
          )}

          <CreatePostModal open={showPostModal} onClose={onClosePostModal} onCompleted={onClosePostModal} />

          <div className="feed-type custom-scrollbar justify-content-center">
            {/* TODO: We haven't implemented news feed for syndicate so return empty with currentRole = 'syndicate' */}
            {newsFeed?.length > 0 && currentRole !== 'syndicate' ? (
              newsFeed?.map((newsfeed, index) => <FeedsCard newsfeed={newsfeed} />)
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                <p className=" joined-headings">No Posts found</p>
              </div>
            )}
          </div>
        </Col>
        <Col xl={4} lg={0} md={0} xs={0} className="md:tw-hidden">
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="trending-heading-small mb-0">
              Startups
              <span className="secondary-text slash-trending-small">_</span>
            </h1>
            {startups?.length > 0 && (
              <button
                type="button"
                className="view-button trending-view-button mt-0"
                onClick={() => handleViewAllClick('startups')}
              >
                View All
              </button>
            )}
          </div>

          <div className="trending-profiles d-flex flex-column gap-4 pt-1 notification-row pb-4 custom-scrollbar">
            {startups?.length > 0 ? (
              <>
                {startups.slice(0, 6).map((item, idx) => (
                  <FeedsProfileCard key={idx} data={item} type="startup" />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">No Startups Found</div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="trending-heading-small mb-0">
              Investors
              <span className="secondary-text slash-trending-small">_</span>
            </h1>
            {investors?.length > 0 && (
              <button
                type="button"
                className="view-button trending-view-button mt-0"
                onClick={() => handleViewAllClick('investors')}
              >
                View All
              </button>
            )}
          </div>

          <div className="trending-profiles d-flex flex-column gap-4 pt-1 notification-row pb-4 custom-scrollbar">
            {investors?.length > 0 ? (
              <>
                {investors.slice(0, 6).map((item, idx) => (
                  <FeedsProfileCard key={idx} data={item} type="investor" />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">No Investors Found</div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="trending-heading-small ">
              Following
              <span className="secondary-text slash-trending-small">_</span>
            </h1>
            {followedProfiles?.length > 0 && (
              <button
                type="button"
                className="view-button trending-view-button mt-0"
                onClick={() => handleViewAllClick('followers')}
              >
                View All
              </button>
            )}
          </div>
          <div className="trending-profiles d-flex flex-column gap-4 pt-1 notification-row pb-4 custom-scrollbar">
            {followedProfiles?.length > 0 ? (
              <>
                {followedProfiles.slice(0, 6).map((item, idx) => (
                  <FeedsProfileCard key={idx} data={item} type={item?.companyName ? 'startup' : 'investor'} />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">No Profiles found</div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FeedsContent;
