import { Tooltip, Typography } from 'antd';

interface Props {
  name: string;
  href?: string | null;
  icon: React.ReactNode;
}

function SocialLink({ name, href, icon }: Props) {
  const getClickableLink = (link: string) => {
    return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
  };

  return (
    <>
      {href ? (
        <a href={getClickableLink(href)} target="_blank" rel="noreferrer">
          {icon}{' '}
          <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-primary hover:tw-underline">
            {name}
          </Typography.Text>
        </a>
      ) : (
        <span className="tw-cursor-not-allowed">
          {icon}{' '}
          <Tooltip title={`${name} hasn't been provided yet`}>
            <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-disabled">{name}</Typography.Text>
          </Tooltip>
        </span>
      )}
    </>
  );
}

export default SocialLink;
