import { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import ProfileCard from '../components/ProfileCard';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import SyndicateService from 'src/api/services/profiles/syndicate.service';
import { BasePaginationParams, BaseResponse } from 'src/api/common';
import useInfiniteScroll from 'src/shared/hooks/useInfiniteScroll';

const SyndicateList = () => {
  const [syndicates, setSyndicates] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const rowsPerPage = 12;

  const { loading } = useApiFetch<BasePaginationParams, BaseResponse & { data: any }>(
    SyndicateService.getAllSyndicates,
    {
      page,
      limit: rowsPerPage,
    },
    {
      onSuccess(response) {
        setSyndicates(prevSyndicates => [...prevSyndicates, ...response?.data?.syndicates]);
        setTotalPages(response?.data?.pagination?.totalPages);
      },
    },
  );

  useInfiniteScroll(loading, () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  });

  return (
    <Spin spinning={loading}>
      {syndicates?.length ? (
        <Row className="card-profiles-row mt-5 mb-5" gutter={[24, 24]}>
          {syndicates?.map((syndicate, index) => (
            <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={24} key={index} className="mb-4">
              <ProfileCard
                role={'syndicate'}
                handle={syndicate?.handle}
                name={syndicate?.name}
                profilePhoto={syndicate?.profilePhoto}
                country={syndicate?.country}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5 mb-4">No syndicates found</div>
      )}
    </Spin>
  );
};

export default SyndicateList;
