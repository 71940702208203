import { Divider, Form, Modal } from 'antd';
import { useUpdatePost } from '../hooks/useUpdatePost';
import { CreatePostDto } from '../../../api/services/posts/posts.dto';
import PostForm from './PostForm';

interface Props {
  postId: string;
  open: boolean;
  initialValues?: CreatePostDto;
  onCompleted: () => void;
  onClose: () => void;
}

const UpdatePostModal = ({ postId, open, initialValues, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm<CreatePostDto>();

  const { handleUpdatePost, loading } = useUpdatePost({
    postId,
    onCompleted: () => {
      form.resetFields();
      onClose();
      onCompleted();
    },
  });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      footer={null}
      width={590}
      title={
        <>
          Edit post
          <Divider />
        </>
      }
    >
      <PostForm form={form} initialValues={initialValues} loading={loading} onSubmit={handleUpdatePost} />
    </Modal>
  );
};

export default UpdatePostModal;
