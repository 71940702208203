import Description from '../../../shared/components/Description';
import { Button, Card, Col, Flex, Row, Tooltip, Typography } from 'antd';
import { CiGlobe } from 'react-icons/ci';
import { AiOutlineEdit } from 'react-icons/ai';
import { useToggle } from '../../../shared/hooks/useToggle';
import { useRef } from 'react';
import { ReactComponent as LinkedInIcon } from '../../../assets/svgs/linkedin-logo.svg';
import InvestorGeneralEditModal from './InvestorGeneralEditModal';
import SocialLink from './SocialLink';

interface Props {
  data: any;
  canUpdate: boolean;
  refetch: () => void;
}

/**
 * InvestorGeneralSection component renders the general information
 */
const InvestorGeneralSection = ({ data, canUpdate, refetch }: Props) => {
  const { open, onClose, onOpen } = useToggle();
  const formInitialValuesRef = useRef({});

  const handleOpenEditModal = () => {
    onOpen();
    formInitialValuesRef.current = {
      investorEmail: data?.investorEmail,
      city: data?.city,
      phoneNumber: data?.phoneNumber,
      address: data?.address,
      linkedIn: data?.socialLinks?.linkedIn,
      websiteUrl: data?.socialLinks?.websiteUrl,
    };
  };

  const handleCompleted = () => {
    refetch();
    onClose();
  };

  return (
    <>
      <InvestorGeneralEditModal
        open={open}
        initialValues={formInitialValuesRef.current}
        onClose={onClose}
        onCompleted={handleCompleted}
      />
      <Card>
        <div className="tw-space-y-4">
          <Flex justify="space-between" align="start" wrap="nowrap">
            <div className="sm:tw-py-2">
              <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
                Contact
              </Typography.Title>

              {canUpdate && (
                <Typography.Text className="tw-text-sm tw-text-text-subTitle tw-mb-0">
                  Contact information will not be shown publicly
                </Typography.Text>
              )}
            </div>
            {canUpdate && (
              <Tooltip title="Edit profile">
                <Button className="tw-flex-shrink-0" type="default" size="small" onClick={handleOpenEditModal}>
                  <AiOutlineEdit size={20} />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <Row gutter={[32, 8]}>
            {canUpdate && (
              <>
                <Col lg={8} md={24} xs={24} className="tw-space-y-2">
                  <Description label="Email">{data?.investorEmail}</Description>
                  <Description label="Phone number">{data?.phoneNumber}</Description>
                </Col>

                <Col lg={8} md={24} xs={24} className="tw-space-y-2">
                  <Description label="City">{data?.city}</Description>
                  <Description label="Address">{data?.address}</Description>
                </Col>
              </>
            )}

            <Col lg={8} md={24} xs={24} className="tw-space-y-2">
              <Flex vertical gap={24}>
                <SocialLink
                  name="Linkedin"
                  href={data?.socialLinks?.linkedIn}
                  icon={<LinkedInIcon width={24} height={24} />}
                />
                <SocialLink
                  name="Website"
                  href={data?.socialLinks?.websiteUrl}
                  icon={<CiGlobe size={24} className="tw-text-text-primary" />}
                />
              </Flex>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default InvestorGeneralSection;
