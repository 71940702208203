import { Col, Divider, Form, Input, Modal, Row, Select, Typography } from 'antd';
import CountrySelect from '../../profile-setup/components/CountrySelect';
import { config } from '../../../config';
import { Pattern } from '../../../shared/utils/validators';
import { useUpdateInvestorProfile } from '../hooks/useUpdateInvestorProfile';
import InvestorStatusSelect from './InvestorStatusSelect';

interface Props {
  open: boolean;
  initialValues?: any;
  onClose: () => void;
  onCompleted: () => void;
}

const InvestorHeaderEditModal = ({ open, initialValues, onClose, onCompleted }: Props) => {
  const [form] = Form.useForm();
  const { handleUpdateInvestorProfile, loading } = useUpdateInvestorProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = (data: any) => {
    handleUpdateInvestorProfile({
      ...data,
      investorStatus: data.investorStatus ?? null,
      mentor: data.mentor ?? null,
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit Profile
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" initialValues={initialValues} size="small" onFinish={handleSubmit}>
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[{ required: true, whitespace: true, message: 'Please enter your first name' }]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[{ required: true, whitespace: true, message: 'Please enter your last name' }]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Handle"
              name="handle"
              rules={[
                { required: true, whitespace: true, message: 'Please enter a handle for your profile' },
                {
                  pattern: Pattern.profileCustomUrl,
                  message: 'Special characters are not allowed',
                },
              ]}
            >
              <Input
                addonBefore={
                  <Typography.Text
                    ellipsis={{ tooltip: `${config?.AppBaseURL}/investor/` }}
                    className="tw-max-w-full tw-truncate md:tw-text-xs tw-text-sm md:tw-max-w-24"
                  >
                    {config?.AppBaseURL}/investor/
                  </Typography.Text>
                }
                placeholder="Enter a url for your profile"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select your country' }]}
            >
              <CountrySelect showSearch />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Investor status" name="investorStatus">
              <InvestorStatusSelect showSearch placeholder="Select investor status" allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Are you interested in mentoring founders?" name="mentor">
              <Select
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                placeholder="Select option"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InvestorHeaderEditModal;
