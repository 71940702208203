import { jwtDecode } from 'jwt-decode';
import React, { createContext, useEffect, ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from 'src/modules/auth/stores/auth.store';
import AUTH_PATHS from 'src/routes/auth.paths';
import useRoleStore from 'src/shared/stores/role.store';
import useUserStore from 'src/shared/stores/user.store';

export const isTokenExpired = (expiration: number) => {
  if (!expiration) {
    return false;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp >= expiration;
};

interface RoleStateProps {
  children: ReactNode;
}

export const RoleContext = createContext({});

/**
 * Handles current role state and other things...
 *
 * TODO This provider is from the MVP, it should be removed
 */
const RoleProvider: React.FC<RoleStateProps> = props => {
  const { setRoles, setCurrentRole } = useRoleStore();
  const { setUser } = useUserStore();
  const { login, setLoading } = useAuthStore();

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    localStorage.clear();
    navigate(AUTH_PATHS.LOGIN);
  }, [navigate]);

  // This function is from the MVP, replace with library as needed
  function parseJwt(token: string | null) {
    if (!token) {
      return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // hashes from social login supabase callback url
    const hashes = new URLSearchParams(location.hash);

    // Check if there are params or hashes in the url to set the token
    const hasParamsOrHashes = params.size > 0 || hashes.size > 0;

    if (hasParamsOrHashes) {
      const googleToken = params.get('token') || hashes.get('#access_token');
      params.delete('token');
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl);

      if (googleToken) {
        localStorage.setItem('token', googleToken);
      }

      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const jwtPayload = jwtDecode(token || '');
      if (isTokenExpired(jwtPayload.exp ?? 0)) {
        handleLogout();
        return;
      }
      const decoded = parseJwt(token);
      if (decoded?.role && Array.isArray(decoded?.role)) {
        login(token);
        setRoles(decoded.role);
        setCurrentRole(decoded.role.includes('investor') ? 'investor' : 'startup');
        // TODO: decoded.user is removed and all needed data is return in the jwt
        setUser({
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          email: decoded.email,
          profilePhoto: decoded.profilePhoto,
          companyName: decoded.companyName,
          companyProfilePhoto: decoded.companyProfilePhoto,
          syndicateName: decoded.syndicateName,
          syndicateProfilePhoto: decoded.syndicateProfilePhoto,
        });
      }
      // if token is from supabase social login redirect token
      if (decoded?.email) {
        setLoading(true);
        login(token);
      }
    }
  }, [
    handleLogout,
    location.pathname,
    location.search,
    login,
    navigate,
    setCurrentRole,
    setRoles,
    setUser,
    location.hash,
    setLoading,
  ]);

  return <RoleContext.Provider value={{}}>{props.children}</RoleContext.Provider>;
};
export default RoleProvider;
