import { Button, Dropdown } from 'antd';
import useRoleStore from 'src/shared/stores/role.store';
import { useFollowProfile } from '../hooks/useFollowProfile';
import { useUnfollowProfile } from '../hooks/useUnfollowProfile';
import { FaAngleDown } from 'react-icons/fa6';

// Props may be undefined due to api request
// TODO Properly handle loading state
interface Props {
  profileId?: string;
  profileType: string;
  isFollowing?: boolean;
  isProfileOwner?: boolean;
  refetch: () => void;
}

const FollowButton = ({ profileId, profileType, isFollowing, isProfileOwner, refetch }: Props) => {
  const { currentRole } = useRoleStore();
  const { handleFollowProfile } = useFollowProfile({ onCompleted: refetch });
  const { handleUnfollowProfile } = useUnfollowProfile({ onCompleted: refetch });

  const handleFollow = () => {
    handleFollowProfile({
      followingId: profileId,
      type: profileType,
      followerRole: currentRole || profileType,
    });
  };

  const handleUnfollow = () => {
    handleUnfollowProfile({
      followingId: profileId,
      type: profileType,
      followerRole: currentRole || profileType,
    });
  };

  // TODO Complete followers implementation for syndicates
  return !isFollowing ? (
    currentRole !== 'syndicate' && (
      <Button className="tw-flex-[3]" disabled={isProfileOwner} type="primary" size="small" onClick={handleFollow}>
        Follow
      </Button>
    )
  ) : (
    <Dropdown
      menu={{
        items: [
          {
            label: (
              <Button type="text" size="small">
                Unfollow
              </Button>
            ),
            key: 'unfollow',
            onClick: handleUnfollow,
          },
        ],
      }}
      placement="bottomRight"
    >
      <Button className="tw-flex-[3]" type="default" size="small" icon={<FaAngleDown size={18} />} iconPosition="end">
        Following
      </Button>
    </Dropdown>
  );
};

export default FollowButton;
