import { useState } from 'react';
import { toast } from 'react-toastify';
import PostService from 'src/api/services/posts/posts.service';

interface Props {
  onCompleted: () => void;
}

export const useDeletePost = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDeletePost = async (id: string) => {
    try {
      setLoading(true);
      const response = await PostService.deletePost(id);

      if (response?.success) {
        onCompleted();
        toast.success('Post has been deleted successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleDeletePost,
    loading,
  };
};
