import APIClient from '../../client';
import { BaseGetOneParams, BaseResponse } from '../../common';
import { CreatePostDto } from './posts.dto';

const POST_ROUTE = '/api/posts';

const PostService = {
  createPost: (data: CreatePostDto | FormData): Promise<BaseResponse> => APIClient.post({ route: POST_ROUTE, data }),
  updatePost: (id: string, data: CreatePostDto | FormData): Promise<BaseResponse> =>
    APIClient.put({ route: `${POST_ROUTE}/${id}`, data }),
  deletePost: (id: string): Promise<BaseResponse> => APIClient.del({ route: `${POST_ROUTE}/${id}` }),
  getPostById: ({ id }: BaseGetOneParams): Promise<BaseResponse> => APIClient.get({ route: `${POST_ROUTE}/${id}` }),
};

export default PostService;
