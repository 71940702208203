import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AuthService, { OldAuthService } from '../../../api/services/auth/auth.service';
import { ChangePasswordDto } from '../../../api/services/auth/auth.dto';
import { useConfigStore } from 'src/shared/stores/config.store';
import { useNavigate } from 'react-router-dom';
import PATHS from 'src/routes/paths';

export const useChangePasswordForm = () => {
  const authService = new AuthService();
  const navigate = useNavigate();
  const { featureFlags } = useConfigStore();
  const [userLoading, setUserLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string | null>(null);

  const handleCancel = () => {
    navigate(PATHS.HOME);
  };

  const getUserInfo = async () => {
    setUserLoading(true);
    try {
      const {
        data: { user },
      } = await authService.getCurrentUser();

      // Disable change password if user is not an email user
      if (user?.app_metadata?.providers?.includes('email') && user?.email) {
        setEmail(user.email);
      }
    }
    catch (error) {
      console.error((error as Error).message);
    }
    finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (featureFlags.supabaseAuth) {
      getUserInfo();
    }
  }, [featureFlags.supabaseAuth]);

  const handleSubmit = async ({ currentPassword, password, confirmPassword }: ChangePasswordDto) => {
    try {
      setLoading(true);

      if (featureFlags.supabaseAuth) {
        if (!email) {
          throw new Error('User not found');
        }

        const { data } = await authService.login(email, currentPassword);

        if (data?.user?.id) {
          const { error } = await authService.updatePassword(password);

          if (error) {
            throw new Error(error.message);
          }

          toast.success('Password changed successfully.');
        }
        else {
          throw new Error('Your current password is incorrect. Please try again.');
        }
      }
      else {
        // Old change password API logic
        const response = await OldAuthService.changePassword({
          currentPassword,
          password,
          confirmPassword,
        });
        if (response.success) {
          toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      }
    }
    catch (error) {
      toast.error((error as Error).message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    handleCancel,
    disabled: featureFlags.supabaseAuth && !email && !userLoading,
    loading,
  };
};
