import { Button, Flex, Image, ImageProps, Spin, Tooltip, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/lib/modal/style';
import 'antd/lib/slider/style';
import useUpload from '../hooks/useUpload';
import { IoClose } from 'react-icons/io5';
import { ReactComponent as ImageIcon } from '../../assets/svgs/image.svg';

interface Props {
  onChange?: (url: string | null) => void;
  file?: string;
  maxSizeInMB?: number;
}

function UploadImage({
  onChange,
  file,
  width = 400,
  height = 400,
  maxSizeInMB = 5,
  ...rest
}: Props & Omit<ImageProps, 'onChange'>) {
  const { fileData, loading, handleUpload, handleBeforeUpload, handleRemove } = useUpload({
    file,
    maxSizeInMB,
    onChange,
  });

  return (
    <Spin spinning={loading}>
      {fileData ? (
        <Flex justify="center" className="tw-rounded-xl tw-relative tw-bg-background-gray">
          <Tooltip title="Remove image">
            <Button
              shape="round"
              size="small"
              className="tw-absolute tw-top-2 tw-right-2 tw-bg-background-white tw-flex tw-justify-center tw-items-center tw-border-none tw-z-10"
              onClick={handleRemove}
            >
              <IoClose size={22} className="tw-absolute tw-text-icon-gray" />
            </Button>
          </Tooltip>
          <Image className="tw-rounded-xl" preview={false} src={fileData} alt="image" {...rest} />
        </Flex>
      ) : (
        <ImgCrop showGrid cropShape="rect" aspect={1} minZoom={1}>
          <Upload
            accept="image/*"
            customRequest={handleUpload}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            maxCount={1}
            className="tw-cursor-pointer"
            style={{ width: '100%' }}
            progress={{
              strokeWidth: 4,
              showInfo: false,
            }}
          >
            <Button type="default" size="small" icon={<ImageIcon width={18} height={18} />}>
              Add image
            </Button>
          </Upload>
        </ImgCrop>
      )}
    </Spin>
  );
}

export default UploadImage;
