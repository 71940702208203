// @ts-nocheck
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { Flex, Grid } from 'antd';

interface Props {
  children: React.ReactNode;
}

const PrivateLayout = ({ children }: Props) => {
  const { lg } = Grid.useBreakpoint();

  return (
    <Sidebar>
      <div className={lg ? 'tw-pl-[270px]' : ''}>
        <Flex vertical align="center" className="tw-w-full tw-py-8 tw-h-full tw-min-h-screen md:tw-py-12">
          {children}
        </Flex>
        <Footer className="simple-container" />
      </div>
    </Sidebar>
  );
};

export default PrivateLayout;
