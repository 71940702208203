import InboxPage from '../views/InboxPage';
import InvestorListPage from '../views/InvestorListPage';
import StartupListPage from '../views/StartupListPage';
import FollowerListPage from '../views/FollowerListPage';
import ChangePasswordPage from '../views/ChangePasswordPage';
import StartupViewPage from '../views/StartupViewPage';
import PostViewPage from '../views/PostViewPage';
import InvestorViewPage from '../views/InvestorViewPage';
import SyndicateViewPage from '../views/SyndicateViewPage';
import SyndicateListPage from '../views/SyndicateListPage';
import FeedsPage from '../views/FeedsPage';
import UserSettingsPage from '../views/UserSettingsPage';
import PROFILE_PATHS from './profile.paths';
import PATHS from './paths';

// TODO Organize by module
// TODO Use nested Route components instead of an array, for better code readability
export const PrivateRoutes = [
  {
    // TODO title isn't used, remove
    title: 'Home',
    component: FeedsPage,
    path: '/',
  },
  // TODO Old, remove after fixing old components hardcoded paths
  {
    title: 'Home',
    component: FeedsPage,
    path: PATHS.HOME,
  },
  {
    title: 'Chat',
    component: InboxPage,
    path: PATHS.CHAT,
  },
  {
    title: 'Startup Public view',
    component: StartupViewPage,
    path: PROFILE_PATHS.STARTUP_PUBLIC,
  },
  {
    title: 'Startup Profile view',
    component: StartupViewPage,
    path: PROFILE_PATHS.STARTUP_YOUR,
  },
  {
    title: 'Investor Public View',
    component: InvestorViewPage,
    path: PROFILE_PATHS.INVESTOR_PUBLIC,
  },
  {
    title: 'Investor Profile View',
    component: InvestorViewPage,
    path: PROFILE_PATHS.INVESTOR_YOUR,
  },
  {
    title: 'Syndicate Public View',
    component: SyndicateViewPage,
    path: PROFILE_PATHS.SYNDICATE_PUBLIC,
  },
  {
    title: 'Syndicate Profile View',
    component: SyndicateViewPage,
    path: PROFILE_PATHS.SYNDICATE_YOUR,
  },
  {
    title: 'Investors',
    component: InvestorListPage,
    path: PATHS.INVESTORS,
  },
  {
    title: 'Startups',
    component: StartupListPage,
    path: PATHS.STARTUPS,
  },
  {
    title: 'Followers',
    component: FollowerListPage,
    path: PATHS.FOLLOWERS,
  },
  {
    title: 'Syndicates',
    component: SyndicateListPage,
    path: PROFILE_PATHS.SYNDICATES,
  },
  {
    title: 'User Setting',
    component: UserSettingsPage,
    path: PATHS.USER_SETTINGS,
  },
  {
    title: 'Change Password',
    component: ChangePasswordPage,
    path: PATHS.CHANGE_PASSWORD,
  },
  {
    title: 'Post View',
    component: PostViewPage,
    path: PATHS.POST,
  },
];
