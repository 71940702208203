import React, { useState } from 'react';
import AngelLogo from './AngelLogo';
import { RiMenu3Fill } from 'react-icons/ri';
import ProfileSwitcher from './ProfileSwitcher';
import { Button } from 'antd';
import FeedbackModal from './feedbackModal';

const Header = ({ toggleSidebar }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleFeedbackButtonClick = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  return (
    <header className="tw-w-screen tw-bg-white tw-shadow-md tw-py-4 tw-px-6 tw-flex tw-items-center tw-justify-between tw-fixed tw-top-0 tw-z-10">
      <div className="tw-flex tw-items-center tw-gap-4">
        <div
          className="tw-items-center tw-cursor-pointer tw-text-lg tw-text-text-primary tw-hidden md:tw-flex"
          onClick={toggleSidebar}
        >
          <RiMenu3Fill className="tw-text-2xl" />
        </div>
        <AngelLogo className="tw-h-12 md:tw-h-8 md:tw-w-36 tw-w-auto" type="home" />
      </div>

      <div className="tw-flex tw-items-center tw-gap-4">
        <Button className="md:tw-hidden" type="default" onClick={handleFeedbackButtonClick}>
          Feedback
        </Button>

        <FeedbackModal
          show={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
        />

        <ProfileSwitcher />
      </div>
    </header>
  );
};

export default Header;
