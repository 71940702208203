import { Col, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import CountrySelect from '../../profile-setup/components/CountrySelect';
import { config } from '../../../config';
import { Pattern } from '../../../shared/utils/validators';
import { useUpdateSyndicateProfile } from '../hooks/useUpdateSyndicateProfile';
import InvestorStatusSelect from './InvestorStatusSelect';

interface Props {
  open: boolean;
  initialValues?: any;
  onClose: () => void;
  onCompleted: () => void;
}

const SyndicateHeaderEditModal = ({ open, initialValues, onClose, onCompleted }: Props) => {
  const [form] = Form.useForm();
  const { handleUpdateSyndicateProfile, loading } = useUpdateSyndicateProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = (data: any) => {
    handleUpdateSyndicateProfile({
      ...data,
      investorStatus: data.investorStatus ?? null,
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit Profile
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" initialValues={initialValues} size="small" onFinish={handleSubmit}>
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item
              label="Syndicate name"
              name="name"
              rules={[{ required: true, whitespace: true, message: 'Please enter your syndicate name' }]}
            >
              <Input placeholder="Enter your syndicate's name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Handle"
              name="handle"
              rules={[
                { required: true, whitespace: true, message: 'Please enter a handle for your syndicate' },
                {
                  pattern: Pattern.profileCustomUrl,
                  message: 'Special characters are not allowed',
                },
              ]}
            >
              <Input
                addonBefore={
                  <Typography.Text
                    ellipsis={{ tooltip: `${config?.AppBaseURL}/syndicate/` }}
                    className="tw-max-w-full tw-truncate md:tw-text-xs tw-text-sm md:tw-max-w-24"
                  >
                    {config?.AppBaseURL}/syndicate/
                  </Typography.Text>
                }
                placeholder="Enter a url for your syndicate profile"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please select your syndicate's country" }]}
            >
              <CountrySelect showSearch />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Syndicate status" name="investorStatus">
              <InvestorStatusSelect showSearch placeholder="Select your syndicate's status" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SyndicateHeaderEditModal;
