import { ThemeConfig } from 'antd';
import { token } from './design-tokens';
import { BUTTON } from './design-tokens/button';
import { CHECKBOX } from './design-tokens/checkbox';
import { FORM } from './design-tokens/form';
import { INPUT } from './design-tokens/input';
import { INPUT_NUMBER } from './design-tokens/inputNumber';
import { TYPOGRAPHY } from './design-tokens/typography';
import { SELECT } from './design-tokens/select';
import { SLIDER } from './design-tokens/slider';
import { CARD } from './design-tokens/card';
import { TAG } from './design-tokens/tag';
import { COLLAPSE } from './design-tokens/collapse';
import { DATE_PICKER } from './design-tokens/datePicker';
import { TOOLTIP } from './design-tokens/tooltip';
import { MENU } from './design-tokens/menu';

export const theme: ThemeConfig = {
  components: {
    Button: BUTTON,
    Form: FORM,
    Input: INPUT,
    InputNumber: INPUT_NUMBER,
    Typography: TYPOGRAPHY,
    Checkbox: CHECKBOX,
    Select: SELECT,
    Slider: SLIDER,
    Card: CARD,
    Tag: TAG,
    Collapse: COLLAPSE,
    DatePicker: DATE_PICKER,
    Tooltip: TOOLTIP,
    Menu: MENU,
  },
  token,
};
