import { Button, Col, Form, Input, Row } from 'antd';
import { CreatePostDto } from '../../../api/services/posts/posts.dto';
import UploadImage from '../../../shared/components/UploadImage';
import { FormInstance } from 'antd/es/form';

interface Props {
  form: FormInstance<CreatePostDto>;
  initialValues?: CreatePostDto;
  loading?: boolean;
  onSubmit: (values: CreatePostDto) => void;
}

const PostForm = ({ form, initialValues, loading, onSubmit }: Props) => {
  const title = Form.useWatch('title', form);
  const description = Form.useWatch('description', form);

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" size="small" onFinish={onSubmit}>
      <Row>
        <Col span={24}>
          <Form.Item<CreatePostDto>
            label="Title"
            name="title"
            rules={[{ required: true, whitespace: true, message: 'Please enter title' }]}
            validateTrigger="onSubmit"
          >
            <Input placeholder="Enter an eye-catching title" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<CreatePostDto>
            label="Description"
            name="description"
            rules={[{ required: true, whitespace: true, message: 'Please enter description' }]}
            validateTrigger="onSubmit"
          >
            <Input.TextArea
              placeholder="Detail your insights for founders and investors"
              autoSize={{ minRows: 6, maxRows: 10 }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="file" valuePropName="file" validateTrigger="onSubmit">
            <UploadImage />
          </Form.Item>
        </Col>
      </Row>

      <Button
        htmlType="submit"
        className="tw-w-full"
        type="primary"
        size="middle"
        loading={loading}
        disabled={!title || !description}
      >
        {initialValues ? 'Save' : 'Post'}
      </Button>
    </Form>
  );
};

export default PostForm;
