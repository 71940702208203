import { CreateInvestmentDto, GetInvestmentParams, UpdateInvestmentDto } from './investment.dto';
import APIClient from '../../client';
import { BaseResponse } from '../../common';

const ROUTES = {
  GET_INVESTMENTS: '/api/investments',
  CREATE_INVESTMENT: '/api/add-investment',
  UPDATE_INVESTMENT: '/api/update-investment',
  DELETE_INVESTMENT: '/api/investment',
};

const InvestmentService = {
  getInvestments: ({ id, page, limit }: GetInvestmentParams): Promise<BaseResponse> =>
    APIClient.get({ route: `${ROUTES.GET_INVESTMENTS}${id ? `/${id}` : ''}?page=${page ?? 1}&limit=${limit ?? 10}` }),
  createInvestment: (data: CreateInvestmentDto): Promise<BaseResponse> =>
    APIClient.post({ route: ROUTES.CREATE_INVESTMENT, data }),
  updateInvestment: (id: string, data: UpdateInvestmentDto): Promise<BaseResponse> =>
    APIClient.put({ route: `${ROUTES.UPDATE_INVESTMENT}/${id}`, data }),
  deleteInvestment: (id: string): Promise<BaseResponse> =>
    APIClient.del({ route: `${ROUTES.DELETE_INVESTMENT}/${id}` }),
};

export default InvestmentService;
