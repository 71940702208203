import { Typography } from 'antd';
import PrivateLayout from '../shared/components/PrivateLayout';
import FollowerList from 'src/modules/profiles/components/FollowerList';

const FollowerListPage = () => {
  return (
    <PrivateLayout>
      <div className="tw-w-full tw-h-full tw-flex tw-justify-start tw-items-start">
        <div className="tw-pt-6 tw-w-full">
          <Typography.Title className="tw-font-semibold tw-text-text-primary md:tw-text-3xl" level={1}>
            Followers <span className="tw-text-text-brand">_</span>
          </Typography.Title>
          <div className="tw-my-12 tw-w-full">
            <FollowerList />
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default FollowerListPage;
