import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * Generates a PDF of the current profile and saves it to the user's downloads
 * folder. If `companyName` is provided, it is used as the filename, otherwise
 * the filename is "profile.pdf".
 *
 * @param {object} options - An object with the following properties:
 *   - `name`: A string to use as the filename for the PDF.
 */
export const generateProfilePDF = ({ name }: { name?: string }) => {
  const section = document.getElementById('section');

  if (!section) {
    return;
  }

  html2canvas(section, { useCORS: true, scale: 2, scrollY: -window.scrollY })
    .then(canvas => {
      const pdf = new jsPDF();
      const imgWidth = pdf.internal.pageSize.getWidth() - 20; // Subtract margins
      const pageHeight = pdf.internal.pageSize.getHeight() - 20; // Subtract margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 10;

      // Convert canvas to image data
      const imgData = canvas.toDataURL('image/png');

      // Add the first page
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add extra pages if content overflows
      while (heightLeft > 0) {
        position -= pageHeight; // Move position to the next page
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Save the PDF
      if (name) {
        pdf.save(`${name}-profile.pdf`);
      }
      else {
        pdf.save('profile.pdf');
      }
    })
    .catch(error => {
      console.error('Error generating PDF:', error);
    });
};
