import useApiFetch from 'src/shared/hooks/useApiFetch';
import PostCard from './PostCard';
import PostService from '../../../api/services/posts/posts.service';
import ErrorSection from '../../../shared/components/ErrorSection';
import { BaseGetOneParams } from 'src/api/common';

interface Props {
  id: string;
}

const PostDetail = ({ id }: Props) => {
  const {
    data: post,
    loading,
    error,
    refetch,
  } = useApiFetch<BaseGetOneParams, any>(PostService.getPostById, {
    id,
  });

  if (error) {
    return <ErrorSection error={error} />;
  }

  return (
    <PostCard
      postId={post?.id}
      title={post?.title}
      description={post?.description}
      image={post?.image}
      createdAt={post?.createdAt}
      user={{
        name: post?.name,
        avatar: post?.userImage,
      }}
      handle={post?.handle}
      role={post?.role}
      loading={loading}
      canUpdate={post?.isOwnedByUser}
      onUpdated={refetch}
    />
  );
};

export default PostDetail;
