import AuthService, { OldAuthService } from '../../../api/services/auth/auth.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { RegisterDto } from '../../../api/services/auth/auth.dto';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useConfigStore } from '../../../shared/stores/config.store';

export const useSignUpForm = () => {
  const navigate = useNavigate();
  const { featureFlags } = useConfigStore();
  const [loading, setLoading] = useState(false);
  const [hasAgreePolicy, setHasAgreePolicy] = useState(false);

  const handleSignUp = async (formData: RegisterDto) => {
    try {
      setLoading(true);
      const { email, password } = formData;
      if (featureFlags.supabaseAuth) {
        const authService = new AuthService();
        const { data, error } = await authService.register(email, password);

        // When confirm email is enable in supabase project, if email is existing, it will return fake user object that we can check by identities field
        if (!data?.user?.identities?.[0]) {
          throw new Error('Email has already been registered, please try another email.');
        }

        if (error) {
          throw error;
        }
        // data.session is null before email is confirmed, set token on verify success screen
        toast.success('User registered successfully. Verify email to login.');
        // TODO Handle /auth/verify/:result in this window/tab, need to track open windows/tabs
        navigate('/');
      }
      else {
        const response = await OldAuthService.register(formData);

        if (!response.success) {
          throw new Error(response.message);
        }
        localStorage.setItem('verify_token', response.data.token);
        navigate('/send-email', { state: { email: formData.email } });
      }
    }
    catch (error) {
      toast.error((error as Error).message);
    }
    finally {
      setLoading(false);
    }
  };

  const handleChangePolicyCheckbox = (e: CheckboxChangeEvent) => {
    setHasAgreePolicy(e.target.checked);
  };

  return {
    handleSignUp,
    loading,
    hasAgreePolicy,
    handleChangePolicyCheckbox,
  };
};
