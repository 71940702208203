import { CreatePostDto } from '../../../api/services/posts/posts.dto';
import PostService from '../../../api/services/posts/posts.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { b64toBlob } from '../../../shared/utils/files';
import useRoleStore from 'src/shared/stores/role.store';

interface Props {
  onCompleted: () => void;
}

export const useCreatePost = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);
  const { currentRole } = useRoleStore();

  const handleCreatePost = async (data: CreatePostDto) => {
    try {
      setLoading(true);

      const { file, title, description } = data;

      const role = currentRole;

      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      if (role) {
        formData.append('role', role);
      }
      if (file) {
        formData.append('file', b64toBlob(file as string));
      }

      const response = await PostService.createPost(formData);

      if (response?.success) {
        onCompleted();
        toast.success('Post created successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleCreatePost,
    loading,
  };
};
