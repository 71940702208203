import { Typography } from 'antd';
import PrivateLayout from '../shared/components/PrivateLayout';
import { WelcomeImage } from '../utils/images';

const InboxPage = () => {
  return (
    <PrivateLayout>
      <div className="tw-w-full tw-h-full tw-flex tw-justify-start tw-items-start">
        <div className="tw-pt-6 tw-w-full">
          <Typography.Title className="tw-font-semibold tw-text-text-primary md:tw-text-3xl" level={1}>
            Inbox <span className="tw-text-text-brand">_</span>
          </Typography.Title>
          <div className="tw-my-12 tw-w-full">
            <div className="welcome-screen d-flex flex-wrap justify-content-center">
              <div className="welcome-header text-center w-100">
                <img src={WelcomeImage} alt="Welcome" />
              </div>
              <div className="welcome-content w-100">
                <h1 className="mb-0">Coming Soon 🚀</h1>
                <p className="mb-1">Send messages to connect with others.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default InboxPage;
