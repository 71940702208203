import { useEffect, useLayoutEffect, useState } from 'react';
import useAuth from './modules/auth/hooks/useAuth';
import { useConfigStore } from './shared/stores/config.store.ts';
import ConfigService from './api/services/config.service';
import { ToastContainer, toast } from 'react-toastify';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { PublicRoutes } from './routes/public.routes';
import AUTH_PATHS from './routes/auth.paths';
import RoleProvider from './context/RoleProvider';
import VerifyResultPage from './views/auth/VerifyResultPage';
import { PrivateRoutes } from './routes/private.routes';
import PrivateRoute from './routes/PrivateRoute';
import ErrorPage from './components/ErrorPage';
import ProfileSetupPage from './views/profile-setup/ProfileSetupPage';
import ResetPasswordResultPage from './views/auth/ResetPasswordResultPage';
import PROFILE_PATHS from './routes/profile.paths';
import { Flex, Spin } from 'antd';

const App = () => {
  const [loading, setLoading] = useState(true);
  useAuth();
  const { featureFlags, setConfig } = useConfigStore();
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);

  const error = params.get('error');

  // TODO: This is a hack to make sure the page is scrolled to the top, can replace by other methods when replacing new router best practice
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getFeatureFlags = async () => {
      try {
        const { featureFlags } = await ConfigService.getAllFeatureFlags();
        setConfig({ featureFlags });
      }
      catch (error) {
        console.error(error);
        // This error likely means the server is unreachable, so give generic error message
        toast.error('Error, see console for details');
      }
      finally {
        setLoading(false);
      }
    };
    getFeatureFlags();
  }, [setConfig]);

  // Render a loading state while the feature flags are being fetched
  if (loading) {
    return (
      <Flex className="tw-w-screen tw-h-screen" justify="center" align="center">
        <Spin size="large" />
      </Flex>
    );
  }

  // Redirect to login page if user cancelled login Linkedin
  if (error === 'user_cancelled_login') {
    return <Navigate to={AUTH_PATHS.LOGIN} />;
  }

  return (
    <div data-testid="app">
      <ToastContainer />
      <RoleProvider>
        <Routes>
          {PublicRoutes?.map((item, index) => (
            <Route key={index} path={item.path} element={<item.component />} />
          ))}

          {featureFlags.supabaseAuth && (
            <>
              <Route path={AUTH_PATHS.RESET_PASSWORD_RESULT} element={<ResetPasswordResultPage />} />
              <Route path={AUTH_PATHS.VERIFY_RESULT} element={<VerifyResultPage />} />
              <Route path={AUTH_PATHS.ERROR} element={<ErrorPage />} />
            </>
          )}

          {PrivateRoutes?.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              element={<PrivateRoute component={item.component} path={item.path} />}
            />
          ))}
          <Route
            path={PROFILE_PATHS.SETUP}
            element={<PrivateRoute path={PROFILE_PATHS.SETUP} component={ProfileSetupPage} />}
          />
          <Route path="*" element={<ErrorPage notFound={true} />} />
        </Routes>
      </RoleProvider>
    </div>
  );
};

export default App;
