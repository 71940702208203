import { Row, Col, Form, Button, Input, Flex, Alert } from 'antd';
import { ChangePasswordDto } from 'src/api/services/auth/auth.dto';
import { useChangePasswordForm } from '../hooks/useChangePasswordForm';
import { Pattern } from 'src/shared/utils/validators';

/* ChangePasswordForm is used with ChangePasswordPage to change user password, different from setting password after verification screen */
const ChangePasswordForm = () => {
  const [form] = Form.useForm();
  const { handleSubmit, handleCancel, disabled, loading } = useChangePasswordForm();

  return (
    <Form form={form} size="large" layout="vertical" onFinish={handleSubmit} disabled={disabled}>
      {disabled && (
        <Alert
          className="tw-mb-4"
          type="warning"
          showIcon
          message="Accounts created with social login cannot add passwords currently"
          description="Please contact support if you require assistance"
        />
      )}
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item<ChangePasswordDto>
            label="Current Password"
            name="currentPassword"
            rules={[
              { required: true, message: 'Please enter your current password' },
              {
                pattern: Pattern.password,
                message: 'Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters.',
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
        </Col>
        <Col xs={0} sm={0} md={12} />
        <Col xs={24} sm={24} md={12}>
          <Form.Item<ChangePasswordDto>
            label="New Password"
            name="password"
            rules={[
              { required: true, message: 'Please enter your new password' },
              {
                pattern: Pattern.password,
                message: 'Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters.',
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item<ChangePasswordDto>
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: 'Please confirm your password' },
              {
                validator: (_, value) => {
                  if (value && value !== form.getFieldValue('password')) {
                    return Promise.reject('Passwords do not match');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder="Confirm your password" />
          </Form.Item>
        </Col>
      </Row>

      <Flex align="center" justify="end" gap={12} className="tw-mt-6">
        <Form.Item>
          <Button type="default" onClick={handleCancel} size="large">
            Cancel
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" loading={loading} htmlType="submit" size="large">
            Save
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
};

export default ChangePasswordForm;
