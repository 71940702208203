import { Link, useNavigate } from 'react-router-dom';
import { Button, Flex, Image, Typography } from 'antd';
import PostPlaceholderImage from 'src/assets/images/post-placeholder.png';
import { getTimeAgo } from '../utils/getTimeAgo';
import Avatar from 'src/shared/components/Avatar';

interface Props {
  newsfeed: any;
}

const FeedsCard = ({ newsfeed }: Props) => {
  const navigate = useNavigate();

  const handleReadMore = (id: string) => {
    navigate(`/post/${id}`);
    window.scroll(0, 0);
  };

  return (
    <Flex className="feeds-post tw-w-full" justify="start" gap={20}>
      <div className="tw-flex tw-justify-center tw-bg-background-gray">
        <Image
          preview={false}
          width={260}
          src={newsfeed?.image || PostPlaceholderImage}
          onError={e => (e.currentTarget.src = PostPlaceholderImage)}
          className="tw-bg-cover tw-bg-center tw-flex-shrink-0 tw-h-full tw-w-full tw-rounded-lg"
        />
      </div>
      <Flex vertical className="tw-w-full tw-min-h-full tw-mt-2">
        <Flex justify="space-between" align="center" className="tw-w-full tw-relative">
          <Typography.Paragraph
            ellipsis={{ rows: 2, tooltip: newsfeed?.title }}
            className="tw-mb-0 tw-font-semibold tw-text-lg tw-text-text-brand tw-w-full"
          >
            {newsfeed?.title}
          </Typography.Paragraph>
          <Typography.Text className="tw-flex-shrink-0 tw-text-xs tw-text-text-body tw-mb-0">
            {getTimeAgo(newsfeed?.createdAt)}
          </Typography.Text>
        </Flex>
        <Typography className="tw-mt-2 tw-flex-grow-[1] tw-pb-5 tw-border-b-[1px] tw-border-solid tw-border-border-disabled ">
          <Typography.Paragraph
            className="tw-overflow-hidden tw-text-xs tw-text-text-subTitle tw-whitespace-pre-line"
            ellipsis={{ rows: 5 }}
          >
            {newsfeed?.description}
          </Typography.Paragraph>
        </Typography>

        <Flex className="tw-flex-shrink-0 tw-w-full" wrap gap={12} align="center" justify="space-between">
          <Link to={newsfeed?.handle ? `/${newsfeed?.role}/${newsfeed?.handle}` : '#'}>
            <Flex align="center" gap={8} className="tw-w-full">
              <Avatar
                src={newsfeed?.userImage}
                role={newsfeed?.role}
                size={58}
                className="tw-border-border-brand tw-border-solid tw-border-[1px] tw-flex-shrink-0"
              />
              <Typography.Paragraph
                className="mb-0 tw-w-full tw-text-sm tw-text-text-gray tw-font-semibold hover:tw-underline"
                ellipsis={{ rows: 1, tooltip: newsfeed?.name }}
              >
                {newsfeed?.name}
              </Typography.Paragraph>
            </Flex>
          </Link>
          <Button
            type="default"
            size="middle"
            className="tw-border-border-brand tw-text-text-brand hover:tw-bg-background-primary hover:tw-text-text-white tw-rounded-xl tw-text-sm"
            onClick={() => handleReadMore(newsfeed?.id)}
          >
            Read More
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeedsCard;
