import { Card, Flex, Image, Skeleton, Space, Typography, Dropdown, Button } from 'antd';
import dayjs from 'dayjs';
import Avatar from '../../../shared/components/Avatar';
import { Role } from '../../../shared/stores/role.store';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useToggle } from '../../../shared/hooks/useToggle';
import DeletePostModal from './DeletePostModal';
import UpdatePostModal from './UpdatePostModal';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../routes/paths';
import PROFILE_PATHS from 'src/routes/profile.paths';

const REDIRECT_PATH = {
  investor: PROFILE_PATHS.INVESTOR_YOUR,
  startup: PROFILE_PATHS.STARTUP_YOUR,
  syndicate: PROFILE_PATHS.SYNDICATE_YOUR,
};

interface Props {
  postId: string;
  user: {
    name: string;
    avatar?: string;
  };
  role: Role;
  createdAt?: string;
  title: string;
  image?: string;
  handle: string;
  description: string;
  loading: boolean;
  canUpdate: boolean;
  onUpdated: () => void;
}

const PostCard = ({
  postId,
  user,
  role,
  createdAt,
  image,
  handle,
  title,
  description,
  loading,
  canUpdate,
  onUpdated,
}: Props) => {
  const navigate = useNavigate();

  const { open: updateModalOpen, onClose: onUpdateModalClose, onOpen: onUpdateModalOpen } = useToggle();
  const { open: deleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useToggle();

  const items = [
    {
      label: (
        <Button className="tw-w-full tw-justify-start" size="small" type="text" onClick={onUpdateModalOpen}>
          Edit
        </Button>
      ),
      key: 'edit',
    },
    {
      label: (
        <Button className="tw-w-full tw-justify-start" size="small" type="text" onClick={onDeleteModalOpen}>
          Delete
        </Button>
      ),
      key: 'delete',
    },
  ];

  if (loading) {
    return (
      <Card bordered={false} className="tw-max-w-[720px] tw-w-full">
        <Space direction="vertical" size={12} className="tw-w-full">
          <Flex justify="center" align="center" className="tw-w-full tw-bg-background-gray tw-rounded-xl">
            <Skeleton.Image active className="tw-w-full tw-h-96" />
          </Flex>

          <Flex gap={8}>
            <Skeleton.Avatar size={48} active />

            <Space direction="vertical" size={12}>
              <Skeleton.Input active className="tw-h-6" />
              <Skeleton.Input active className="tw-h-3" />
            </Space>
          </Flex>

          <Skeleton active paragraph={{ rows: 3 }} title={{ className: 'tw-h-6' }} />
        </Space>
      </Card>
    );
  }

  const handleDeleteCompleted = () => {
    navigate(REDIRECT_PATH[role] || PATHS.HOME);
  };

  const handleEditCompleted = () => {
    onUpdateModalClose();
    onUpdated();
  };

  return (
    <>
      <UpdatePostModal
        postId={postId}
        open={updateModalOpen}
        onClose={onUpdateModalClose}
        onCompleted={handleEditCompleted}
        initialValues={{ title, description, role, file: image }}
      />

      <DeletePostModal
        open={deleteModalOpen}
        onClose={onDeleteModalClose}
        onCompleted={handleDeleteCompleted}
        postId={postId}
      />

      <Card bordered={false} className="tw-max-w-[720px] tw-w-full">
        <Space direction="vertical" size={12} className="tw-w-full">
          {image && (
            <Flex justify="center" align="center" className="tw-w-full tw-bg-background-gray tw-rounded-xl">
              <Image src={image} />
            </Flex>
          )}

          <Flex align="start" justify="space-between" className="tw-w-full">
            <Flex gap={8} align="center" className="tw-flex-shrink-0 tw-w-full">
              <div className="tw-flex-shrink-0">
                <Link to={`/${role}/${handle}`}>
                  <Avatar role={role} src={user?.avatar} size={48} />
                </Link>
              </div>

              <Typography.Link
                href={`/${role}/${handle}`}
                ellipsis
                className="tw-text-text-primary tw-text-sm tw-font-semibold tw-mb-0 hover:tw-underline tw-w-full"
              >
                {user.name}
              </Typography.Link>
            </Flex>

            {canUpdate && (
              <div className="tw-flex-shrink-0">
                <Dropdown menu={{ items }} placement="bottomLeft">
                  <Button type="text" size="small" className="tw-h-4">
                    <BsThreeDotsVertical />
                  </Button>
                </Dropdown>
              </div>
            )}
          </Flex>

          <Space direction="vertical" size={8}>
            <div>
              <Typography.Title level={5} className="tw-font-semibold tw-mb-0 tw-leading-5">
                {title}
              </Typography.Title>
              <Typography.Text className="tw-text-text-subTitle tw-text-xs">
                {dayjs(createdAt).format('DD MMM YYYY [at] HH:mm')}
              </Typography.Text>
            </div>

            <Typography.Paragraph className="tw-text-sm tw-text-text-body tw-whitespace-pre-line">
              {description}
            </Typography.Paragraph>
          </Space>
        </Space>
      </Card>
    </>
  );
};

export default PostCard;
