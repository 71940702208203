import { Button, Col, Flex, Row, Space, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoTrashBinOutline } from 'react-icons/io5';
import Description from 'src/shared/components/Description';

interface Props {
  id: string;
  canUpdate: boolean;
  companyName: string;
  dealDate?: string;
  dealType?: string;
  dealSize?: string;
  industry?: string;
  companyStage?: string;
  leadInvestor?: string;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const InvestmentItem = ({
  id,
  canUpdate,
  companyName,
  dealDate,
  dealType,
  dealSize,
  industry,
  companyStage,
  leadInvestor,
  onDelete,
  onEdit,
}: Props) => {
  const handleDelete = (id: string) => () => {
    onDelete(id);
  };

  const handleEdit = (id: string) => () => {
    onEdit(id);
  };

  return (
    <Space direction="vertical" size={16} className="tw-w-full">
      <Flex justify="space-between" align="center" className="tw-w-full" wrap="nowrap">
        <div>
          <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
            {companyName}
          </Typography.Title>
          <Typography.Text className="tw-text-xs tw-text-text-placeholder">
            {[dealDate ? dayjs(dealDate).format('MM/YYYY') : null, companyStage, dealSize ?? null]
              .filter(Boolean)
              .join(' · ')}
          </Typography.Text>
        </div>

        {canUpdate && (
          <Flex justify="end" align="center" gap={2} className="tw-flex-shrink-0">
            <Tooltip title="Delete">
              <Button size="small" type="text" onClick={handleDelete(id)}>
                <IoTrashBinOutline size={22} className="tw-text-text-placeholder" />
              </Button>
            </Tooltip>
            <Tooltip title="Edit">
              <Button size="small" type="text" onClick={handleEdit(id)}>
                <AiOutlineEdit size={22} className="tw-text-text-placeholder" />
              </Button>
            </Tooltip>
          </Flex>
        )}
      </Flex>

      <Row gutter={[16, 16]}>
        <Col md={24} lg={8} xs={24}>
          <Description label="Lead investor">{leadInvestor}</Description>
        </Col>
        <Col md={24} lg={8} xs={24}>
          <Description label="Deal type">{dealType}</Description>
        </Col>
        <Col md={24} lg={8} xs={24}>
          <Description label="Industry">{industry ? <Tag className="tw-text-sm">{industry}</Tag> : null}</Description>
        </Col>
      </Row>
    </Space>
  );
};

export default InvestmentItem;
