import { RxCross2 } from 'react-icons/rx';
import { Button, Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Helper } from '../utils/images';

const { Title, Text } = Typography;

const HelperBanner = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();

  const handleExploreClick = (value: string) => {
    navigate(`/${value}`);
  };

  return (
    <div className="tw-relative tw-bg-center tw-rounded-lg tw-shadow-md tw-mt-4 tw-py-4 tw-px-6 helper-banner-bg">
      <div
        className="tw-absolute tw-top-2 tw-right-2 tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-background-primary tw-cursor-pointer"
        onClick={onClose}
      >
        <RxCross2 className="tw-text-white" size={16} />
      </div>

      <Row align="middle" gutter={[16, 16]} className="tw-flex tw-pt-4">
        <Col xs={24} sm={12}>
          <div className="tw-space-y-4">
            <Title level={4} className="tw-text-text-brand tw-text-lg tw-font-semibold tw-leading-snug">
              Follow more profiles and stay updated on what's happening in the world.
            </Title>
            <Text className="tw-text-text-body tw-text-sm tw-leading-relaxed tw-block">
              Follow other profiles to stay updated with the latest news and highlights from our community.
            </Text>
            <div className="tw-flex tw-gap-2">
              <Button size="small" type="primary" onClick={() => handleExploreClick('investors')}>
                Explore Investors
              </Button>
              <Button size="small" type="default" onClick={() => handleExploreClick('startups')}>
                Explore Startups
              </Button>
            </div>
          </div>
        </Col>

        <Col xs={0} sm={12} className="xs:tw-hidden sm:tw-hidden tw-flex tw-justify-center">
          <img src={Helper} alt="Banner" className="tw-max-w-full tw-h-auto tw-max-h-40" />
        </Col>
      </Row>
    </div>
  );
};

export default HelperBanner;
