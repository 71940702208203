import { Button, Card, Divider, Dropdown, Flex, Grid, Tooltip, Typography } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { useToggle } from '../../../shared/hooks/useToggle';
import StartupHeaderEditModal from './StartupHeaderEditModal';
import { useRef } from 'react';
import { handleCopyProfileUrl } from '../utils/handleCopyProfileUrl';
import StartupMakeProfilePrivateModal from './StartupMakeProfilePrivateModal';
import { useUpdateStartupProfilePrivacy } from '../hooks/useUpdateStartupProfilePrivacy';
import { generateProfilePDF } from '../utils/generateProfilePdf';
import UploadAvatar from '../../../shared/components/UploadAvatar';
import { useUpdateStartupProfile } from '../hooks/useUpdateStartupProfile';
import UploadCover from '../../../shared/components/UploadCover';
import { ReactComponent as Logo } from '../../../assets/svgs/angel-logo-white.svg';
import { b64toBlob } from '../../../shared/utils/files';
import { toast } from 'react-toastify';
import Avatar from 'src/shared/components/Avatar';
import FollowButton from './FollowButton';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { classNames } from 'src/shared/utils/classNames';

interface Props {
  canUpdate: boolean;
  profilePhoto?: string;
  coverPhoto?: string;
  companyName?: string;
  handle?: string;
  country?: string;
  elevatorPitch?: string;
  numberOfFollowers?: number;
  isPrivateProfile?: boolean;
  isFollowing?: boolean;
  isProfileOwner?: boolean;
  id?: string;
  refetch: () => void;
}

const StartupHeadingSection = ({
  canUpdate,
  profilePhoto,
  coverPhoto,
  companyName,
  handle,
  country,
  elevatorPitch,
  numberOfFollowers,
  isPrivateProfile,
  isFollowing,
  isProfileOwner,
  id,
  refetch,
}: Props) => {
  const { md, sm } = Grid.useBreakpoint();

  const { open: editProfileModalOpen, onClose: onCloseEditModal, onOpen: onOpenEditModal } = useToggle();
  const { open: privacyModalOpen, onClose: onClosePrivacyModal, onOpen: onOpenPrivacyModal } = useToggle();
  const { handleSubmit } = useUpdateStartupProfile({ onCompleted: refetch });
  const formInitialValuesRef = useRef({});

  const onStartupProfilePrivacyUpdated = () => {
    refetch();
    onClosePrivacyModal();
  };
  const { handleUpdateStartupProfilePrivacy } = useUpdateStartupProfilePrivacy({
    onCompleted: onStartupProfilePrivacyUpdated,
  });

  const handleChangePrivacy = () => {
    if (isPrivateProfile) {
      handleUpdateStartupProfilePrivacy({ isPrivateProfile: false });
    }
    else {
      onOpenPrivacyModal();
    }
  };

  const handleChangeProfilePicture = (data: string | null) => {
    if (!data) {
      toast.error('Profile photo not found, please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('profilePhoto', b64toBlob(data));
    handleSubmit(formData);
  };

  const handleChangeCoverPhoto = (data: string | null) => {
    if (!data) {
      toast.error('Cover photo not found, please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('coverPhoto', b64toBlob(data));
    handleSubmit(formData);
  };

  const items = [
    {
      label: (
        <Button
          className="tw-w-full tw-justify-start"
          size="small"
          type="text"
          onClick={() => handleCopyProfileUrl(handle, 'startup')}
        >
          Copy profile link
        </Button>
      ),
      key: 'copy',
    },
    {
      label: (
        <Button
          className="tw-w-full tw-justify-start"
          size="small"
          type="text"
          onClick={() => generateProfilePDF({ name: companyName })}
        >
          Download page as pdf
        </Button>
      ),
      key: 'download-pdf',
    },
    {
      label: (
        <Button className="tw-w-full tw-justify-start" size="small" type="text" onClick={handleChangePrivacy}>
          {isPrivateProfile ? 'Make profile public' : 'Make profile private'}
        </Button>
      ),
      key: 'set-private',
    },
    ...(handle
      ? [
          {
            label: (
              <Button
                className="tw-w-full tw-justify-start"
                size="small"
                type="text"
                href={`/startup/${handle}`}
                target="_blank"
              >
                View as visitor
              </Button>
            ),
            key: 'view-as-visitor',
          },
        ]
      : []),
  ];

  const handleOpenEditModal = () => {
    onOpenEditModal();
    formInitialValuesRef.current = {
      companyName,
      country,
      handle,
      companyProfile: elevatorPitch,
    };
  };

  const handleCompleted = () => {
    refetch();
    onCloseEditModal();
  };

  return (
    <>
      <StartupHeaderEditModal
        open={editProfileModalOpen}
        onClose={onCloseEditModal}
        initialValues={formInitialValuesRef.current}
        onCompleted={handleCompleted}
      />

      <StartupMakeProfilePrivateModal
        open={privacyModalOpen}
        onClose={onClosePrivacyModal}
        onCompleted={onStartupProfilePrivacyUpdated}
      />

      <Card
        cover={
          <div className="tw-relative">
            {canUpdate ? (
              <UploadCover width={996} height={360} onChange={handleChangeCoverPhoto} file={coverPhoto} />
            ) : (
              <>
                {coverPhoto ? (
                  <img
                    alt="example"
                    src={coverPhoto}
                    className="tw-object-cover tw-aspect-cover tw-bg-center tw-bg-no-repeat tw-rounded-t-xl tw-w-full tw-h-full"
                  />
                ) : (
                  <div className="tw-bg-background-black tw-text-center tw-rounded-t-xl">
                    <Logo
                      style={{
                        height: '30vw',
                        width: '35vw',
                        maxHeight: '360px',
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className="tw-absolute md:tw-bottom-[-46px] lg:tw-bottom-[-54px] tw-bottom-[-108px] sm:tw-pl-3 tw-pl-6">
              {canUpdate ? (
                <UploadAvatar
                  file={profilePhoto}
                  size={md ? 153 : 92}
                  role="startup"
                  onChange={handleChangeProfilePicture}
                  className="tw-border-4 tw-border-text-white"
                />
              ) : (
                <Avatar
                  role="startup"
                  src={profilePhoto}
                  size={md ? 153 : 92}
                  className="tw-border-4 tw-border-text-white"
                />
              )}
            </div>
          </div>
        }
      >
        <div className="tw-space-y-3">
          <Flex vertical={!md && !canUpdate} justify="space-between" gap={8} align={md ? 'center' : 'start'}>
            <div className="tw-space-y-1 md:tw-pt-11 tw-pl-40 md:tw-pl-0 tw-overflow-hidden">
              <Typography.Title ellipsis level={4} className="tw-mb-0 tw-font-semibold tw-text-text-primary">
                {companyName}
              </Typography.Title>
              <Typography.Text className="tw-block tw-text-xs tw-text-text-body">{country}</Typography.Text>
              <Button type="link" className="tw-h-8 tw-p-0 tw-text-primary-500" size="small">
                {numberOfFollowers} {numberOfFollowers === 1 ? 'follower' : 'followers'}
              </Button>
            </div>

            <Flex
              gap={8}
              align="center"
              className={classNames({
                'tw-w-fit': md,
                'tw-w-full': !md && !canUpdate,
                'tw-absolute tw-right-3': !sm && canUpdate,
                'tw-absolute tw-right-6': !md && sm && canUpdate,
              })}
            >
              {canUpdate ? (
                <>
                  <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']}>
                    <Button type="default" size="small">
                      More
                    </Button>
                  </Dropdown>

                  <Tooltip title="Edit profile">
                    <Button type="default" size="small" onClick={handleOpenEditModal}>
                      <AiOutlineEdit size={20} />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <FollowButton
                    isProfileOwner={isProfileOwner}
                    profileId={id}
                    profileType="startup"
                    isFollowing={isFollowing}
                    refetch={refetch}
                  />
                  {md ? (
                    <Button type="default" size="small" onClick={() => generateProfilePDF({ name: companyName })}>
                      Download page
                    </Button>
                  ) : (
                    <Button
                      className="tw-flex-[2]"
                      type="default"
                      size="small"
                      onClick={() => handleCopyProfileUrl(handle, 'startup')}
                    >
                      Copy profile link
                    </Button>
                  )}

                  {md ? (
                    <Button type="default" size="small" onClick={() => handleCopyProfileUrl(handle, 'startup')}>
                      Copy profile link
                    </Button>
                  ) : (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              <Button
                                className="tw-w-full tw-justify-start"
                                size="small"
                                type="text"
                                onClick={() => generateProfilePDF({ name: companyName })}
                              >
                                Download page as pdf
                              </Button>
                            ),
                            key: 'download-pdf',
                          },
                        ],
                      }}
                      placement="bottomLeft"
                      trigger={['click']}
                    >
                      <Button className="tw-flex-none" type="default" size="small">
                        <BsThreeDotsVertical size={20} />
                      </Button>
                    </Dropdown>
                  )}
                </>
              )}
            </Flex>
          </Flex>

          {elevatorPitch && (
            <>
              <Divider />

              <Typography.Paragraph
                ellipsis={{
                  rows: 3,
                  expandable: 'collapsible',
                  symbol: expanded => (
                    <span className="tw-font-medium tw-text-text-primary">{expanded ? 'Less' : ' Read More'}</span>
                  ),
                }}
                className="tw-text-md tw-font-medium tw-text-text-body"
              >
                {elevatorPitch}
              </Typography.Paragraph>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default StartupHeadingSection;
