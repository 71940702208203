import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { DummyImage } from '../utils/images/index';
import FormLabel from './FormLabel';
import InputField from './InputField';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { HiOutlineArrowRight } from 'react-icons/hi2';
import OldApi from '../api-old/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { countries } from '../constants';
import { handleDragEnter, handleDragLeave, handleDragOver } from '../utils';
import ImageCropModal from './imageCropModal';

const UserSettings = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [previewImage, setPreviewImage] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [modalPreviewImage, setModalPreviewImage] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isPhotoUpdated, setIsPhotoUpdated] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    file: null,
    email: '',
  });
  const [userImage, setUserImage] = useState(null);

  const getUserSettings = async () => {
    try {
      const response = await OldApi.getUserSettings(token);
      if (response.success) {
        const phoneNumber =
          response?.data?.phoneCode && !response?.data?.phoneNumber?.includes('+')
            ? response?.data?.phoneCode + response?.data?.phoneNumber
            : response?.data?.phoneNumber || '';

        setUserImage(response?.data?.profilePhoto);
        setPreviewImage(response?.data?.profilePhoto);
        setFormValues(prevFormValues => ({
          ...prevFormValues,
          file: response?.data?.profilePhoto || null,
          firstName: response?.data?.firstName || '',
          lastName: response?.data?.lastName || '',
          phoneNumber,
          country: response?.data?.country || '',
          email: response?.data?.email || '',
        }));

        // Load phone validation
        setIsPhoneNumberValid(isValidPhoneNumber(phoneNumber));
        setSpinner(false);
      }
    }
    catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handlePhoneChange = value => {
    if (value) {
      const validation = isValidPhoneNumber(value);
      setIsPhoneNumberValid(validation);
    }
    setFormValues(formValues => ({
      ...formValues,
      phoneNumber: value,
    }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === 'phoneNumber') {
      const numericValue = value.replace(/\D/g, '');
      setFormValues({
        ...formValues,
        [name]: numericValue,
      });
    }
  };

  const handleDrop = e => {
    const maxSizeInBytes = 2 * 1024 * 1024;
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.size > maxSizeInBytes) {
        toast.error('Image size is larger than 2MB. Please upload a smaller file.');
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setModalPreviewImage(reader.result);
        setShowProfileModal(true);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange = e => {
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file) {
      if (file.size > maxSizeInBytes) {
        toast.error('Image size is larger than 2MB. Please upload a smaller file.');
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setModalPreviewImage(reader.result);

        setShowProfileModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (
      !formValues.firstName ||
      !formValues.lastName ||
      !formValues.phoneNumber ||
      !formValues.country ||
      !isPhoneNumberValid
    ) {
      setErrors(true);
      return false;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      for (const key in formValues) {
        if (key !== 'phoneNumber' && key !== 'email' && key !== 'file') {
          formData.append(key, formValues[key]);
        }
      }
      if (isPhotoUpdated) {
        formData.append('file', formValues?.file);
      }
      formData.append('phoneNumber', formValues.phoneNumber);

      const response = await OldApi.updateUserSettings(formData, token);
      if (response.success) {
        toast.success(response?.message);
        localStorage.setItem('userName', formValues?.firstName + ' ' + formValues?.lastName);
      }
    }
    catch (error) {
      toast.error(error?.data?.message);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getUserSettings();
  }, []);

  return (
    <Container fluid className="main-content dashboard-main">
      <div className="dash-content">
        {spinner ? (
          <div className="text-center visual-loader">
            <Spinner animation="border" role="status" style={{ width: '2rem', height: '2rem', color: '#ff4814' }}>
              <span className="visually-hidden ">Loading...</span>
            </Spinner>
            <p className="mt-2 joined-headings">Loading...</p>
          </div>
        ) : (
          <Col lg={12} md={12} sm={12} className="user-settings">
            <h1 className="trending-heading mb-0">
              User Settings
              <span className="secondary-text slash-trending">_</span>
            </h1>

            <div className="mt-4 login-form">
              <ImageCropModal
                showModal={showProfileModal}
                imgURL={modalPreviewImage}
                onSaveHandler={setPreviewImage}
                currentPhoto={userImage}
                setCurrentPhoto={setUserImage}
                isImageUpdated={setIsPhotoUpdated}
                name="file"
                setFormValues={setFormValues}
                onModalClose={() => {
                  setShowProfileModal(false);
                }}
              />
              <div
                className={`image-uploader mb-5 ${isDragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver(setIsDragging)}
                onDragEnter={handleDragEnter(setIsDragging)}
                onDragLeave={handleDragLeave(setIsDragging)}
                onDrop={e => handleDrop(e)}
              >
                <input type="file" id="fileInput" onChange={handleImageChange} accept="image/*" />
                <label htmlFor="fileInput" className="upload-label">
                  <img src={previewImage ? previewImage : DummyImage} alt="" className="preview-image" />
                </label>
              </div>
              <Row className="profile-setup mb-5">
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                    <FormLabel className="form-label" labelText="First Name" required={true} />
                    <InputField
                      className="form-input"
                      placeholder="Enter First Name"
                      type="text"
                      name="firstName"
                      value={formValues.firstName}
                      onChange={handleChange}
                    />
                    {errors && !formValues.firstName && (
                      <p className="error-message secondary-text mb-0 mt-1">Please Enter First Name</p>
                    )}
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                    <FormLabel className="form-label" labelText="Last Name" required={true} />
                    <InputField
                      className="form-input"
                      placeholder="Enter Last Name"
                      type="text"
                      name="lastName"
                      value={formValues.lastName}
                      onChange={handleChange}
                      required
                    />
                    {errors && !formValues.lastName && (
                      <p className="error-message secondary-text mb-0 mt-1">Please Enter Last Name</p>
                    )}
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                    <FormLabel className="form-label label-phone" labelText="Phone Number " required={true} />

                    <div className="input-field form-input d-flex gap-2">
                      <PhoneInput
                        defaultCountry="HK"
                        placeholder="Enter phone number"
                        addInternationalOption={false}
                        name="phoneNumber"
                        value={formValues?.phoneNumber}
                        onChange={handlePhoneChange}
                      />
                    </div>
                    {errors && !formValues.phoneNumber ? (
                      <p className="error-message secondary-text mb-0 mt-1">Please Enter Phone Number</p>
                    ) : errors && !isPhoneNumberValid ? (
                      <p className="error-message secondary-text mb-0">Please enter valid phone number</p>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>

                <Col lg={6} md={12} xs={12}>
                  <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                    <FormLabel className="form-label" labelText="Country" required={true} />
                    <select
                      id="country"
                      name="country"
                      value={formValues.country}
                      onChange={handleChange}
                      className="form-input form-select-select"
                    >
                      <option value="" disabled selected>
                        Select a country
                      </option>
                      {countries?.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    {errors && !formValues.country && (
                      <p className="error-message secondary-text mb-0 mt-1">Please Enter Country</p>
                    )}
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                    <FormLabel className="form-label" labelText="Email Address" />
                    <InputField
                      className="form-input"
                      placeholder="Email Address"
                      type="text"
                      name="email"
                      value={formValues.email}
                      disabled={true}
                      required
                      style={{ backgroundColor: '#f5f5f5', color: '#808080' }}
                    />
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12} className=" column-pwd">
                  <div className=" d-flex gap-3 justify-content-end align-items-center">
                    <button className=" view-button mt-0 update-button" onClick={() => navigate('/home')}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={e => handleSubmit(e)}
                      className=" user-settings-btn  update-button text-white d-flex gap-2 justify-content-center align-items-center"
                    >
                      Update
                      {loading ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      ) : (
                        <HiOutlineArrowRight className="right-arrow" />
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </div>
    </Container>
  );
};

export default UserSettings;
