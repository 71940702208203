import { PropsWithChildren, useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { TbHome2, TbBadge } from 'react-icons/tb';
import { PiUsersThreeLight } from 'react-icons/pi';
import { TiWorldOutline } from 'react-icons/ti';
import { RiSettings4Line } from 'react-icons/ri';
import { HiOutlineChatBubbleLeft } from 'react-icons/hi2';
import { RxCross2 } from 'react-icons/rx';
// @ts-ignore
import Header from './Header';
import useRoleStore from '../shared/stores/role.store';

const sidebarMenuItemProps = {
  className:
    'tw-h-11 tw-flex tw-items-center tw-py-2 tw-rounded-lg tw-cursor-pointer tw-rounded-xl md:tw-text-sm md:tw-h-10 tw-flex-shrink-0 md:tw-h-8',
};

const Sidebar = ({ children }: PropsWithChildren<unknown>) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { currentRole } = useRoleStore();
  const location = useLocation(); // Get the current route

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    document.body.classList.toggle('no-scroll', isSidebarOpen);
  };

  const getActiveMenuKey = () => {
    const path = location.pathname;
    if (path.includes('home') || path === '/') return 'home';
    if (path.includes('investors')) return 'investors';
    if (path.includes('startups')) return 'startups';
    if (path.includes('syndicates')) return 'syndicates';
    if (path.includes('followers')) return 'followers';
    if (path.includes('chat')) return 'chat';
    if (path.includes('user-settings')) return 'user-settings';
    return '';
  };

  return (
    <div className="dashboard">
      <span
        className={`overlay-styled ${isSidebarOpen ? 'is-active' : ''}`}
        onClick={() => setIsSidebarOpen(false)}
      ></span>
      <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <div className="main-dashboard">
        <div className={`sidebar tw-overflow-x-hidden ${isSidebarOpen ? 'open' : ''}`}>
          <div className="cross-icon" onClick={toggleSidebar}>
            <RxCross2 className="opener" />
          </div>
          <Menu
            className="tw-p-0 tw-pr-8 tw-flex tw-flex-col tw-gap-2 tw-h-full tw-border-right-0 tw-w-[250px] tw-overflow-y-auto"
            selectedKeys={[getActiveMenuKey()]} // Use location to set active key
          >
            <Menu.Item key="home" icon={<TbHome2 className="sidebar-icon" />} {...sidebarMenuItemProps}>
              <Link to="/home">Home</Link>
            </Menu.Item>
            <Menu.Item key="investors" icon={<PiUsersThreeLight className="sidebar-icon" />} {...sidebarMenuItemProps}>
              <Link to="/investors">Investors</Link>
            </Menu.Item>
            <Menu.Item key="startups" icon={<TbBadge className="sidebar-icon" />} {...sidebarMenuItemProps}>
              <Link to="/startups">Startups</Link>
            </Menu.Item>
            <Menu.Item key="syndicates" icon={<PiUsersThreeLight className="sidebar-icon" />} {...sidebarMenuItemProps}>
              <Link to="/syndicates">Syndicates</Link>
            </Menu.Item>
            {currentRole !== 'syndicate' && (
              <Menu.Item key="followers" icon={<TiWorldOutline className="sidebar-icon" />} {...sidebarMenuItemProps}>
                <Link to="/followers">Following</Link>
              </Menu.Item>
            )}
            <Menu.Item key="chat" icon={<HiOutlineChatBubbleLeft className="sidebar-icon" />} {...sidebarMenuItemProps}>
              <Link to="/chat">Inbox</Link>
            </Menu.Item>
            <Menu.Item
              key="user-settings"
              icon={<RiSettings4Line className="sidebar-icon" />}
              {...sidebarMenuItemProps}
            >
              <Link to="/user-settings">User Settings</Link>
            </Menu.Item>
          </Menu>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
