import { CreatePostDto } from '../../../api/services/posts/posts.dto';
import PostService from '../../../api/services/posts/posts.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useRoleStore from 'src/shared/stores/role.store';
import { b64toBlob, isBase64 } from 'src/shared/utils/files';

interface Props {
  postId: string;
  onCompleted: () => void;
}

export const useUpdatePost = ({ postId, onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);
  const { currentRole } = useRoleStore();

  const handleUpdatePost = async (data: CreatePostDto) => {
    try {
      setLoading(true);

      const { file, title, description } = data;

      const role = currentRole;

      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      if (role) {
        formData.append('role', role);
      }
      if (file && isBase64(file as string)) {
        formData.append('file', b64toBlob(file as string));
      }

      const response = await PostService.updatePost(postId, formData);

      if (response?.success) {
        onCompleted();
        toast.success('Post edited successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdatePost,
    loading,
  };
};
