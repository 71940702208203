import { Button, Card, Dropdown, Flex, Grid, Tag, Tooltip, Typography } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { useToggle } from '../../../shared/hooks/useToggle';
import { useRef } from 'react';
import { handleCopyProfileUrl } from '../utils/handleCopyProfileUrl';
import { generateProfilePDF } from '../utils/generateProfilePdf';
import UploadAvatar from '../../../shared/components/UploadAvatar';
import UploadCover from '../../../shared/components/UploadCover';
import { ReactComponent as Logo } from '../../../assets/svgs/angel-logo-white.svg';
import { b64toBlob } from '../../../shared/utils/files';
import { useUpdateSyndicateProfile } from '../hooks/useUpdateSyndicateProfile';
import SyndicateHeaderEditModal from './SyndicateHeaderEditModal';
import { useUpdateSyndicateProfilePrivacy } from '../hooks/useUpdateSyndicateProfilePrivacy';
import { toast } from 'react-toastify';
import Avatar from 'src/shared/components/Avatar';
import SyndicateMakeProfilePrivateModal from './SyndicateMakeProfilePrivateModal';
import { UpdateSyndicateDto } from 'src/api/services/profiles/syndicate.dto';
import { classNames } from 'src/shared/utils/classNames';

interface Props {
  canUpdate: boolean;
  profilePhoto?: string;
  coverPhoto?: string;
  name?: string;
  handle?: string;
  country?: string;
  investorStatus?: string;
  numberOfFollowers?: number;
  isPrivate?: boolean;
  refetch: () => void;
}

const SyndicateHeadingSection = ({
  canUpdate,
  profilePhoto,
  coverPhoto,
  name,
  handle,
  country,
  investorStatus,
  isPrivate,
  refetch,
}: Props) => {
  const { md, sm } = Grid.useBreakpoint();

  const { open: editProfileModalOpen, onClose: onCloseEditModal, onOpen: onOpenEditModal } = useToggle();
  const { open: privacyModalOpen, onClose: onClosePrivacyModal, onOpen: onOpenPrivacyModal } = useToggle();
  const { handleUpdateSyndicateProfile } = useUpdateSyndicateProfile({ onCompleted: refetch });
  const formInitialValuesRef = useRef({});

  const onSyndicateProfilePrivacyUpdated = () => {
    refetch();
    onClosePrivacyModal();
  };
  const { handleUpdateSyndicateProfilePrivacy } = useUpdateSyndicateProfilePrivacy({
    onCompleted: onSyndicateProfilePrivacyUpdated,
  });

  const handleChangePrivacy = () => {
    if (isPrivate) {
      handleUpdateSyndicateProfilePrivacy({ isPrivate: false });
    }
    else {
      onOpenPrivacyModal();
    }
  };

  const handleChangeProfilePicture = (data: string | null) => {
    if (!data) {
      toast.error('Profile photo not found, please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('profilePhoto', b64toBlob(data));
    handleUpdateSyndicateProfile(formData as UpdateSyndicateDto);
  };

  const handleChangeCoverPhoto = (data: string | null) => {
    if (!data) {
      toast.error('Cover photo not found, please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('coverPhoto', b64toBlob(data));
    handleUpdateSyndicateProfile(formData as UpdateSyndicateDto);
  };

  const items = [
    {
      label: (
        <Button
          className="tw-w-full tw-justify-start"
          size="small"
          type="text"
          onClick={() => handleCopyProfileUrl(handle, 'syndicate')}
        >
          Copy profile link
        </Button>
      ),
      key: 'copy',
    },
    {
      label: (
        <Button
          className="tw-w-full tw-justify-start"
          size="small"
          type="text"
          onClick={() => generateProfilePDF({ name })}
        >
          Download page as pdf
        </Button>
      ),
      key: 'download-pdf',
    },
    {
      label: (
        <Button className="tw-w-full tw-justify-start" size="small" type="text" onClick={handleChangePrivacy}>
          {isPrivate ? 'Make profile public' : 'Make profile private'}
        </Button>
      ),
      key: 'set-private',
    },
    {
      label: (
        <Button
          className="tw-w-full tw-justify-start"
          size="small"
          type="text"
          href={`/syndicate/${handle}`}
          target="_blank"
        >
          View as visitor
        </Button>
      ),
      key: 'view-as-visitor',
    },
  ];

  const handleOpenEditModal = () => {
    onOpenEditModal();
    formInitialValuesRef.current = {
      name,
      country,
      handle,
      investorStatus,
    };
  };

  const handleCompleted = () => {
    refetch();
    onCloseEditModal();
  };

  return (
    <>
      <SyndicateHeaderEditModal
        open={editProfileModalOpen}
        onClose={onCloseEditModal}
        initialValues={formInitialValuesRef.current}
        onCompleted={handleCompleted}
      />

      <SyndicateMakeProfilePrivateModal
        open={privacyModalOpen}
        onClose={onClosePrivacyModal}
        onCompleted={onSyndicateProfilePrivacyUpdated}
      />

      <Card
        cover={
          <div className="tw-relative">
            {canUpdate ? (
              <UploadCover width={996} height={360} onChange={handleChangeCoverPhoto} file={coverPhoto} />
            ) : (
              <>
                {coverPhoto ? (
                  <img
                    alt="example"
                    src={coverPhoto}
                    className="tw-object-cover tw-aspect-cover tw-bg-center tw-bg-no-repeat tw-rounded-t-xl tw-w-full tw-h-full"
                  />
                ) : (
                  <div className="tw-bg-background-black tw-text-center tw-rounded-t-xl">
                    <Logo
                      style={{
                        height: '30vw',
                        width: '35vw',
                        maxHeight: '360px',
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className="tw-absolute md:tw-bottom-[-46px] lg:tw-bottom-[-54px] tw-bottom-[-108px] sm:tw-pl-3 tw-pl-6">
              {canUpdate ? (
                <UploadAvatar
                  file={profilePhoto}
                  size={md ? 153 : 92}
                  role="syndicate"
                  onChange={handleChangeProfilePicture}
                  className="tw-border-4 tw-border-text-white"
                />
              ) : (
                <Avatar
                  role="syndicate"
                  src={profilePhoto}
                  size={md ? 153 : 92}
                  className="tw-border-4 tw-border-text-white"
                />
              )}
            </div>
          </div>
        }
      >
        <Flex vertical={!md && !canUpdate} justify="space-between" gap={8} align={md ? 'center' : 'start'}>
          <div className="tw-space-y-3 tw-pl-40 md:tw-pl-0 tw-min-h-16 tw-overflow-hidden">
            <div className="tw-space-y-1 md:tw-pt-11">
              <Typography.Title ellipsis level={4} className="tw-mb-0 tw-font-semibold tw-text-text-primary">
                {name}
              </Typography.Title>

              <Typography.Text className="tw-block tw-text-xs tw-text-text-body">{country}</Typography.Text>

              {investorStatus && (
                <Tag className="tw-text-xs tw-text-text-primary tw-px-4 tw-py-1">{investorStatus}</Tag>
              )}
            </div>
          </div>

          <Flex
            gap={8}
            align="center"
            className={classNames({
              'tw-w-fit': md,
              'tw-w-full': !md && !canUpdate,
              'tw-absolute tw-right-3': !sm && canUpdate,
              'tw-absolute tw-right-6': !md && sm && canUpdate,
            })}
          >
            {canUpdate ? (
              <>
                <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click', 'hover']}>
                  <Button type="default" size="small">
                    More
                  </Button>
                </Dropdown>

                <Tooltip title="Edit profile">
                  <Button type="default" size="small" onClick={handleOpenEditModal}>
                    <AiOutlineEdit size={20} />
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Button className="tw-flex-1" type="default" size="small" onClick={() => generateProfilePDF({ name })}>
                  Download page
                </Button>
                <Button
                  className="tw-flex-1"
                  type="default"
                  size="small"
                  onClick={() => handleCopyProfileUrl(handle, 'syndicate')}
                >
                  Copy profile link
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default SyndicateHeadingSection;
