import { Button, Dropdown, Flex, Image, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import PostPlaceholderImage from '../../../assets/images/post-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'src/shared/hooks/useToggle';
import UpdatePostModal from './UpdatePostModal';
import DeletePostModal from './DeletePostModal';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Role } from 'src/shared/stores/role.store';

interface Props {
  createdAt?: string;
  id: string;
  image?: string;
  title: string;
  description?: string;
  role: Role;
  canUpdate: boolean;
  onUpdated: () => void;
}

const PostItem = ({ id, createdAt, image, title, description, role, canUpdate, onUpdated }: Props) => {
  const navigate = useNavigate();

  const { open: updateModalOpen, onClose: onUpdateModalClose, onOpen: onUpdateModalOpen } = useToggle();
  const { open: deleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useToggle();

  const items = [
    {
      label: (
        <Button className="tw-w-full tw-justify-start" size="small" type="text" onClick={onUpdateModalOpen}>
          Edit
        </Button>
      ),
      key: 'edit',
    },
    {
      label: (
        <Button className="tw-w-full tw-justify-start" size="small" type="text" onClick={onDeleteModalOpen}>
          Delete
        </Button>
      ),
      key: 'delete',
    },
  ];

  const handleRedirectToPostDetail = () => {
    navigate(`/post/${id}`);
    window.scroll(0, 0);
  };

  const handleDeleteCompleted = () => {
    onDeleteModalClose();
    onUpdated();
  };

  const handleEditCompleted = () => {
    onUpdateModalClose();
    onUpdated();
  };

  return (
    <Space direction="vertical" size={8} className="tw-w-full">
      <UpdatePostModal
        postId={id}
        open={updateModalOpen}
        onClose={onUpdateModalClose}
        onCompleted={handleEditCompleted}
        initialValues={{ title, description: String(description), role, file: image }}
      />

      <DeletePostModal
        open={deleteModalOpen}
        onClose={onDeleteModalClose}
        onCompleted={handleDeleteCompleted}
        postId={id}
      />

      <Flex justify="space-between" align="center" className="tw-w-full">
        <Typography.Text className="tw-text-xs tw-text-text-placeholder tw-flex-shrink-0">
          {dayjs(createdAt).format('DD MMM YYYY [at] HH:mm')}
        </Typography.Text>
      </Flex>

      <Flex gap={12} align="start">
        <div className="tw-flex-shrink-0">
          <Image
            preview={false}
            width={80}
            height={80}
            src={image || PostPlaceholderImage}
            onError={e => (e.currentTarget.src = PostPlaceholderImage)}
            className="tw-bg-cover tw-bg-center tw-flex-shrink-0"
          />
        </div>

        <Flex align="start" justify="space-between" className="tw-w-full tw-max-w-full tw-overflow-hidden tw-truncate">
          <div className="tw-space-y-1 tw-w-full tw-max-w-[calc(100%-32px)]">
            <Typography.Text
              onClick={handleRedirectToPostDetail}
              ellipsis={{ tooltip: title }}
              className="tw-mb-0 tw-text-base tw-font-semibold tw-text-text-primary hover:tw-underline tw-cursor-pointer"
            >
              {title}
            </Typography.Text>

            <Typography.Paragraph
              className="tw-text-base tw-text-text-placeholder tw-whitespace-pre-line"
              ellipsis={{ rows: 2 }}
            >
              {description}
            </Typography.Paragraph>
          </div>

          {canUpdate && (
            <div className="tw-flex-shrink-0">
              <Dropdown menu={{ items }} placement="bottomLeft">
                <Button type="text" size="small" className="tw-h-4">
                  <BsThreeDotsVertical />
                </Button>
              </Dropdown>
            </div>
          )}
        </Flex>
      </Flex>
    </Space>
  );
};

export default PostItem;
